"use client"
import React from "react";
import Link from 'next/link';
import { Chip } from "@nextui-org/chip";
import {Image} from "@nextui-org/image";
import { useBreakpoint } from "@/src/hooks/useBreakpoint";

export default function MovieLargeCard({movie}) {
    const { isDownMd } = useBreakpoint('md');
    return (
        <Link prefetch={false} href={`/xem-phim/${movie.slug}`}>
            <div className="flex shadow-small rounded-xl w-full relative overflow-hidden bg-black m-0 pt-[60%]">
                <div className="self-center w-full absolute top-[0%]">
                    <div className={`overflow-hidden flex justify-center`}>
                        <Image removeWrapper alt={movie.name} isZoomed={isDownMd ? false : true} src={movie.thumb_image} className={`w-full h-full object-cover`}/>
                    </div>
                </div>
                <div className="absolute bottom-0 z-10 px-4 pb-4 pt-10 w-full bg-gradient-to-t from-black">
                    <h2 className="text-sm md:text-lg font-bold text-white overflow-hidden whitespace-nowrap max-w-[360px] text-ellipsis">
                        {movie.name}
                    </h2>
                </div>
                
                <div className="absolute top-1 right-1 z-10">  
                    {
                        movie.language && movie.language !== 'none' &&
                        <Chip className="text-white" size="sm" radius="md" color="danger">
                            {movie.language && movie.language}
                        </Chip>
                    }
                    {
                        movie.id_category === 2 && 
                        <Chip size="sm" className="ml-2 text-white" radius="md" color="primary">
                            { movie.total_episode == movie.current_episode ? `FULL ${movie.total_episode}/${movie.total_episode}` : `Tập ${movie.current_episode}` }
                        </Chip>
                    }
                </div>
            </div>
        </Link>
    );
}
